// Count the number of letters in a string
export const countLetters = (str: string): Map<string, number> => {
  const letters = new Map<string, number>();
  for (const char of str) {
    if (letters.has(char)) {
      letters.set(char, letters.get(char)! + 1);
    } else {
      letters.set(char, 1);
    }
  }
  return letters;
};

// Filter letters from a string based on a given count
export const filterLetters = (
  str: string,
  counts: Map<string, number>
): [string, Map<string, number>] => {
  // Clone the counts map to avoid modifying the original map
  const filteredCounts = new Map(counts);

  // Create a new string by iterating over the input string and checking if the character is present in the counts map
  let result = "";
  for (const char of str) {
    if (filteredCounts.has(char) && filteredCounts.get(char)! > 0) {
      result += char;
      filteredCounts.set(char, filteredCounts.get(char)! - 1);
    }
  }
  return [result, filteredCounts];
};
