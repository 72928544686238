export const ruRURub = new Intl.NumberFormat("ru-RU", {
  style: "currency",
  currency: "RUB",
});
export const ruRUPercent = new Intl.NumberFormat("ru-RU", { style: "percent" });
export const ruRUPercentFrac = (minFraction: number, maxFraction: number) =>
  new Intl.NumberFormat("ru-RU", {
    style: "percent",
    minimumFractionDigits: minFraction,
    maximumFractionDigits: maxFraction,
  });
export const ruRUNumber = (minFraction: number, maxFraction: number) =>
  new Intl.NumberFormat("ru-RU", {
    style: "decimal",
    minimumFractionDigits: minFraction,
    maximumFractionDigits: maxFraction,
  });
